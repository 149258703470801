<script lang="ts" setup></script>

<template>
	<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="2.75" y="2.75" width="20.5" height="3.5" rx="1.25" stroke="black" stroke-width="1.5" />
		<rect x="2.75" y="9.75" width="20.5" height="3.5" rx="1.25" stroke="black" stroke-width="1.5" />
		<rect x="2.75" y="16.75" width="20.5" height="3.5" rx="1.25" stroke="black" stroke-width="1.5" />
	</svg>
</template>

<style scoped lang="scss"></style>
