<script lang="ts" setup></script>

<template>
	<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M23.8 20.9999C23.8 18.91 21.7965 17.132 19 16.473M16.6 21C16.6 18.349 13.3764 16.2 9.4 16.2C5.42355 16.2 2.2 18.349 2.2 21M16.6 12.6C19.251 12.6 21.4 10.451 21.4 7.8C21.4 5.14903 19.251 3 16.6 3M9.4 12.6C6.74903 12.6 4.6 10.451 4.6 7.8C4.6 5.14903 6.74903 3 9.4 3C12.051 3 14.2 5.14903 14.2 7.8C14.2 10.451 12.051 12.6 9.4 12.6Z"
			stroke="black"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<style scoped lang="scss"></style>
