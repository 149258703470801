<script lang="ts" setup></script>

<template>
	<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.2 13L8.6 14M1 10H4.2L6.69771 6.18807L8.28046 4.13878C8.66859 4 10.0371 4 10.0371 4H15.9629C16.8752 4 17.3314 4 17.7195 4.13878L19.3023 6.18807L21.8 10H25M17.4 14L21.8 13M7.28 10C7.28 10 4.50774 10 3.80183 10.327C3.1809 10.6146 2.67606 11.0735 2.35968 11.638C2 12.2798 2 13.1198 2 14.8V17.5C2 17.9647 2 19.197 2.04227 19.3902C2.21587 20.1836 2.89808 20.8038 3.7708 20.9616C3.98332 21 4.23888 21 4.75 21C4.75 21 4.08497 21 5.3 21C6.51503 21 6.4 19.1046 6.4 18C6.4 17.7239 9.94624 16.5 10.25 16.5H13H15.75C16.0538 16.5 19.6 17.7239 19.6 18C19.6 19.1046 19.485 21 20.7 21H21.25C21.7611 21 22.0167 21 22.2292 20.9616C23.1019 20.8038 23.7841 20.1836 23.9577 19.3902C24 19.197 24 17.9647 24 17.5V14.8C24 13.1198 24 12.2798 23.6403 11.638C23.3239 11.0735 22.8191 10.6146 22.1982 10.327C21.4923 10 20.5682 10 18.72 10C18.72 10 15.75 9 13 9C10.25 9 7.28 10 7.28 10Z"
			stroke="black"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M6 20H20" stroke="black" stroke-width="1.5" />
	</svg>
</template>

<style scoped lang="scss"></style>
