<script lang="ts" setup></script>

<template>
	<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17 13.3744C20.5318 14.0688 23 15.6547 23 17.5C23 19.9853 18.5228 22 13 22C7.47715 22 3 19.9853 3 17.5C3 15.6547 5.46819 14.0688 9 13.3744M13 18V8M13 8C14.6569 8 16 6.65685 16 5C16 3.34315 14.6569 2 13 2C11.3431 2 10 3.34315 10 5C10 6.65685 11.3431 8 13 8Z"
			stroke="black"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<style scoped lang="scss"></style>
