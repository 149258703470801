<script lang="ts" setup>
	useHead({
		titleTemplate: (string) => {
			return string ? `${string} - Aston Martin` : "Aston Martin";
		},
	});
</script>

<template>
	<div class="background"></div>

	<Header />
	<main class="flex-vertical"><slot /></main>
</template>

<style scoped lang="scss">
	main {
		justify-content: center;
		align-items: center;
		z-index: 1;
		padding-top: 124px;
		padding-bottom: 50px;
		padding-left: 50px;
		padding-right: 50px;
	}
</style>
