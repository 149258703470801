<script lang="ts" setup></script>

<template>
	<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3 20H5M5 20H16M5 20V14.3682C5 13.8428 5 13.58 5.063 13.335C5.11883 13.1178 5.21073 12.9118 5.33496 12.7252C5.47505 12.5147 5.67114 12.3384 6.06152 11.9877L8.3631 9.91997C9.11784 9.24192 9.49549 8.90264 9.92249 8.77393C10.2989 8.66045 10.7007 8.66045 11.0771 8.77393C11.5045 8.90275 11.8827 9.2422 12.6387 9.92139L14.9387 11.9877C15.3295 12.3388 15.5245 12.5146 15.6647 12.7252C15.7889 12.9118 15.8807 13.1178 15.9365 13.335C15.9995 13.58 16 13.8428 16 14.3682V20M16 20H21M21 20H23M21 20V7.19691C21 6.07899 21 5.5192 20.7822 5.0918C20.5905 4.71547 20.2837 4.40973 19.9074 4.21799C19.4796 4 18.9203 4 17.8002 4H11.2002C10.0801 4 9.51962 4 9.0918 4.21799C8.71547 4.40973 8.40973 4.71547 8.21799 5.0918C8 5.51962 8 6.08009 8 7.2002V10.0002"
			stroke="black"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<style scoped lang="scss"></style>
