<script lang="ts" setup></script>

<template>
	<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="3.75" y="2.75" width="14.5" height="14.5" rx="2.25" stroke="black" stroke-width="1.5" />
		<rect x="7.75" y="6.75" width="14.5" height="14.5" rx="2.25" fill="white" stroke="black" stroke-width="1.5" />
	</svg>
</template>

<style scoped lang="scss"></style>
