<script lang="ts" setup>
	import routes from "~/utils/routes";
	import { useUser } from "~/stores/user";

	const { id, permissions } = useUser();
	const { y } = useWindowScroll();
	const shadowShow = ref(false);
	watch(
		y,
		() => {
			process();
		},
		{ immediate: true }
	);

	function process() {
		if (y.value > 10) {
			shadowShow.value = true;
		} else {
			shadowShow.value = false;
		}
	}

	const route = useRoute();

	onMounted(() => {
		process();
	});
</script>

<template>
	<header class="flex-vertical">
		<nav class="flex-horizontal">
			<ul class="flex-horizontal">
				<li class="flex-vertical" v-if="permissions?.pages === 'all'">
					<NuxtLink :to="routes.pages.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.pages.index) || route.path.includes(routes.paymentPages.index) }]">
						<IconPages />
						<p class="small">Pages</p>
					</NuxtLink>
				</li>
				<li class="flex-vertical" v-if="permissions?.loans === 'all'">
					<NuxtLink :to="routes.loans.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.loans.index) }]">
						<IconLoans />
						<p class="small">Loans</p>
					</NuxtLink>
				</li>
				<li class="flex-vertical" v-if="permissions?.vehicles === 'all'">
					<NuxtLink :to="routes.vehicles.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.vehicles.index) }]">
						<IconVehicles />
						<p class="small">Vehicles</p>
					</NuxtLink>
				</li>

				<li class="flex-vertical" v-if="permissions?.dealerships === 'all'">
					<NuxtLink :to="routes.dealerships.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.dealerships.index) }]">
						<IconDealerships />
						<p class="small">Dealerships</p>
					</NuxtLink>
				</li>
				<li class="flex-vertical" v-if="permissions?.models === 'all'">
					<NuxtLink :to="routes.models.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.models.index) }]">
						<IconModels />
						<p class="small">Models</p>
					</NuxtLink>
				</li>
				<li class="flex-vertical" v-if="permissions?.users === 'all'">
					<NuxtLink :to="routes.users.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.users.index) }]">
						<IconUsers />
						<p class="small">Users</p>
					</NuxtLink>
				</li>
			</ul>
			<logo />
			<NuxtLink :to="routes.profile" class="profile flex-horizontal">
				<RecordUser v-if="id" :id="id" v-slot="{ record }">
					<p>{{ record?.first_name }} {{ record?.last_name }}</p>
					<profile-picture :id="id" size="small" />
				</RecordUser>
			</NuxtLink>
		</nav>

		<div class="shadow" :class="[{ show: shadowShow }]"></div>
	</header>
</template>

<style scoped lang="scss">
	header {
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 3;
		width: 100%;
		pointer-events: none;
		nav {
			pointer-events: all;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 74px;
			background: var(--white);
			border-bottom: 1px solid var(--beige-dark);

			padding-left: 20px;
			padding-right: 20px;

			ul {
				height: 100%;
				list-style: none;
				li {
					height: 100%;
					a {
						height: 100%;
						align-items: center;
						justify-content: center;
						padding-left: 20px;
						padding-right: 20px;
						transition: all 0.2s ease-out;
						p {
							color: var(--black);
						}
					}

					a:hover {
						box-shadow: inset 0px -2px 0px var(--beige-dark);
					}
					a.router-link-active,
					a.active {
						box-shadow: inset 0px -2px 0px var(--green);
					}
				}
			}
			.profile {
				padding-left: 20px;
				padding-right: 20px;
				align-items: center;
				justify-content: center;
				height: 100%;
				transition: all 0.2s ease-out;
				gap: 10px;
				p {
					color: var(--black);
				}
			}

			.profile:hover {
				box-shadow: inset 0px -2px 0px var(--beige-dark);
			}
			.profile.router-link-active {
				box-shadow: inset 0px -2px 0px var(--green);
			}

			:deep(.logo) {
				height: 36px;
				width: auto;
				position: absolute;
				left: 50%;
				top: 19px;
				transform: translateX(-50%);
			}
		}

		.shadow {
			display: flex;
			width: 100%;
			height: 30px;
			background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.4s ease-out;
		}

		.shadow.show {
			opacity: 0.02;
		}
	}
</style>
